import React from 'react';
import { connect } from 'react-redux';
 const uiActions = iceStarkData.store.get('commonAction')?.ui;

import SideMenu from './SideMenu';
import ArrowLeft from '@/icons/ArrowLeft';

@connect(
  (state) => { 
    return { sidebarStatus: state.ui.sidebarStatus }
  },
  (dispatch) => {
    return {
      toggleSidebar: () => {dispatch(uiActions.toggleSidebar())} 
    }
  }
)
class Sidebar extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    
  }
  
  render() {
    const { sidebarStatus, toggleSidebar } = this.props;

    return (
      <div className="sidebar">
        {
          !sidebarStatus ? '' :
          <div className="qc-menu-fold qc-menu-fold-left" onClick={() => { toggleSidebar(); }}>
            <ArrowLeft className="menu-arrow" width="16" height="16" />
          </div>
        }
        <h2 className="headline">
          <span className="head-title">嘉豪SCRM平台</span>
        </h2>

   
        <SideMenu ></SideMenu>
        
      </div>
    );
  }
}

export default Sidebar; 