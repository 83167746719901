// import '@babel/polyfill';
import ReactDOM from 'react-dom';
import React from 'react';
// import * as stores from './stores';
import App from './pages/App';
import './styles/index.scss';
import 'moment/locale/zh-cn';
import configureStore from './redux/store/configureStore';

import { isInIcestark, setLibraryName } from'@ice/stark-app';
// isInIcestark 判断当前运行环境，是否运行在 icestark 环境中，返回值类型：boolean
// setLibraryName 配置微应用导出的 umd 全局变量。
import { store as layoutStore } from '@ice/stark-data';

const initialState = window.__INITIAL_STATE__;
export const store = configureStore(initialState);
// configure({ enforceActions: 'always' });
// Date = function (Date) {
//   MyDate.prototype = Date.prototype;
//   return MyDate;

//   function MyDate() {
//     // 当只有一个参数并且参数类型是字符串时，把字符串中的-替换为/
//     if (arguments.length === 1) {
//       let arg = arguments[0];
//       if (Object.prototype.toString.call(arg) === '[object String]' && arg.indexOf('T') === -1) {
//         arguments[0] = arg.replace(/-/g, "/");
//         // console.log(arguments[0]);
//       }
//     }
//     let bind = Function.bind;
//     let unbind = bind.bind(bind);
//     return new (unbind(Date, null).apply(null, arguments));
//   }
// }(Date);

// 导出对应的生命周期
// ice-stark
export function mount(props) {
  ReactDOM.render(<App store={layoutStore.get('commonStore')} {...props.customProps || {}}/>, props.container);
}

export function unmount(props) {
  ReactDOM.unmountComponentAtNode(props.container);
}

// 注意：`setLibraryName` 的入参需要与 webpack 工程配置的 output.library 保持一致
// setLibraryName('microApp');
setLibraryName(process.env.moduleApp);

if(!isInIcestark()) {
  ReactDOM.render((
    <App store={store} />
  ), document.getElementById('root'));
}



