import React from 'react';
import { connect } from 'react-redux';
import { Input, Button, Dialog, FormGroupList, StaticFormGroup } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm, SubmissionError } from 'redux-form';
import Hint from '../../../icons/Hint';
import IconAdvisory from '../../../icons/IconAdvisory';
const { invitationCodes, registration } = iceStarkData.store.get('commonAction')?.users;
import './index.scss';
import iconUcode from '../../../assets/icon-ucode.png';


const required = (val) => {
    if (!val) {
        return '邀请码不能为空';
    }
    return null;
};

const asyncValidate = (val) => {
    console.log(val)
    return new Promise(resolve => { resolve() }).then(() => {
        if (val.error) {
            throw ({ invitationCode: '邀请码无效，主账号尚未登记企业信息，请联系主账号！' });
        }
        return '';
    })
}

@connect(
    state => ({ user: state.users, backend: state.backend, }),
    { invitationCodes: invitationCodes.REQUEST, registration: registration.REQUEST }
)
class UcodeLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasSubmit: false // 是否做过提交操作
        };
    }
    componentDidMount() {
    }

    componentDidUpdate(preProps) {
        // if (this.props.user.codesError !== preProps.user.codesError) {
        //     this.props.asyncValidate('error', 'error', 'blur')
        // }
        if (this.props.user.invitationCodes !== preProps.user.invitationCodes) {
            this.setState({ hasSubmit: true });
        }
    }

    // 渲染底部
    handleFooterRender = () => {
        const { handleSubmit, user: { invitationCodes } } = this.props;
        const { hasSubmit } = this.state;
        return (
            <div className="tc-15-rich-dialog-ft-btn-wrap">
                {(!invitationCodes || !hasSubmit) && <Button onClick={handleSubmit(this.handleSubmit)}>确定</Button>}
                {invitationCodes && hasSubmit && <Button onClick={handleSubmit(this.handleLogin)}>登陆</Button>}
                <Button className="tc-15-btn weak" onClick={() => { this.setState({ visible: false, hasSubmit: false }) }}>取消</Button>
            </div>
        );
    }

    // 提交
    handleSubmit = (data) => {
        this.props.invitationCodes(data);
    }

    // 登陆
    handleLogin = (data) => {
        const { backend } = this.props;
        this.props.registration({ openId: backend.open_id, ...data });
    }

    render() {
        const { visible, hasSubmit } = this.state;
        const { user: { invitationCodes }, hideButton } = this.props;
        const { eseName, name, mobile, email } = invitationCodes || {};
        return (
            <div className="ucode-login">
                <div className="block">
                    <img className="icon-ucode" src={iconUcode} />
                    <div>
                        <div>优码基于一物一码助力品牌实现营销/管理信息化升级。包括品牌营销、供应链溯源、防伪防窜、风控管理、大数据服务等。</div>
                        {
                            !hideButton &&
                            <div>· 服务使用，请点击<a onClick={() => { this.setState({ visible: true }) }}>立即使用</a>输入邀请码</div>
                        }
                        
                        <div>· 服务申请，请联系您的商务经理，或<a href="https://cloud.tencent.com/login?s_url=https%3A%2F%2Fcloud.tencent.com%2Fact%2Fapply%2Fqcloud_QRcode" target="_blank">立即咨询</a><IconAdvisory/></div>
                        <div className="bt-group">
                            {   !hideButton &&
                                <Button onClick={() => { this.setState({ visible: true }) }}>立即使用</Button>
                            }
                            <a className="tc-15-btn default" href="https://cloud.tencent.com/login?s_url=https%3A%2F%2Fcloud.tencent.com%2Fact%2Fapply%2Fqcloud_QRcode" target="_blank">立即咨询</a>
                        </div>
                    </div>
                </div>

                <Dialog
                    className="ucode-login-dialog"
                    visible={visible}
                    onCancel={() => { this.setState({ visible: false }) }}
                    footerRender={this.handleFooterRender}
                >
                    <FormGroupList>
                        <div>
                            <div className="form-label">
                                <label className="invitation">邀请码
                                    {!hasSubmit && <span className="hint">
                                        <Hint className="icon-hint" />
                                        <div className="text">
                                            <div>若您还未获取邀请码，可致电4006-365-103咨询,咨询时间9:30-17:30</div>
                                            <span></span>
                                        </div>
                                    </span>}
                                </label>
                            </div>
                            <FormGroupField
                                className="form-input-invitationCode"
                                name="invitationCode"
                                placeholder="请输入邀请码"
                                disabled={hasSubmit}
                                component={Input}
                                validate={[required]}
                            />
                            {hasSubmit && <a onClick={() => { this.setState({ hasSubmit: false }) }}>修改</a>}
                        </div>
                        {eseName && <div className={`block ${hasSubmit ? 'active' : ''}`}>
                            <StaticFormGroup label="企业名称">{eseName}</StaticFormGroup>
                            <StaticFormGroup label="姓名&#12288;&#12288;">{name}</StaticFormGroup>
                            <StaticFormGroup label="手机号码">{mobile}</StaticFormGroup>
                            <StaticFormGroup label="邮箱地址">{email}</StaticFormGroup>
                        </div>}
                    </FormGroupList>
                </Dialog>
            </div>
        )
    }
}


export default reduxForm({
    form: 'ucodeLoginForm',
    // asyncValidate,
    initialValues: {
    }
})(UcodeLogin)