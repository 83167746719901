import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';


export const rootEpic = combineEpics(
  
);


export const rootReducer = combineReducers({
  
});
